<!-- 服务费模板 -->
<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <search-inner title="" :search-id="1" :search-form="searchFormExpand" @submit-search="searchExpand" @clear-search="resetExpand">
        <template>
          <el-col :span="6">
            <el-form-item label="用信编号：">
              <el-input v-model="searchFormExpand.useCode" placeholder="请输入要搜索的用信编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="服务费编号：">
              <el-input v-model="searchFormExpand.serviceChargeCode" placeholder="请输入要搜索的服务费编号" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资金方：">
              <el-input v-model="searchFormExpand.capName" placeholder="请输入要搜索的资金方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="核心企业：">
              <el-input v-model="searchFormExpand.coreName" placeholder="请输入要搜索的核心企业" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="运营方：">
              <el-input v-model="searchFormExpand.channelName" placeholder="请输入要搜索的运营方" />
            </el-form-item>
          </el-col>
        </template>
      </search-inner>
      <div class="list-btn-inner" />
      <month-list v-if="isExpand === 0" ref="refMonthList" />
      <record-list v-if="isExpand === 1" ref="refRecordList" :search-form="searchFormExpand" />
    </div>
  </el-main>
</template>
<script>
import MonthList from '@/views/serviceChargeManagement/record/month-list'
import RecordList from '@/views/serviceChargeManagement/record/record-list'
class FormProp {
  id = ''
  fileList = []
}
export default {
  components: { MonthList, RecordList },
  data () {
    return {
      formInfo: new FormProp(),
      isExpand: 0,
      searchForm: {
        useCode: '',
        templateCode: '',
        capName: '',
        coreName: '',
        channelName: ''
      },
      searchFormExpand: {
        month: '',
        useCode: '',
        serviceChargeCode: '',
        capName: '',
        coreName: '',
        channelName: ''
      },
      loading: {
        list: false,
        expandList: false,
        submit: false
      },
      exportDialog: {
        title: '',
        visible: false
      },
      // 获取row的key值
      getRowKeys(row) {
        return row.month;
      },
      expandKeys: [],
      tableList: {},
      serviceList: {},
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      }
    }
  },
  mounted () {
  },
  created () {},
  methods: {

    expandChange (row, expandedRows) {
      let that = this
      if (expandedRows.length) {
        that.expandKeys = []
        if (row) {
          that.searchFormExpand.month = row.month
          that.expandKeys.push(row.month)
          this.getByMonth({month: row.month})
        }
      } else {
        that.expandKeys = []
      }
    },
    getNowDate () {
      let date = new Date()
      let y = date.getFullYear()
      let m = date.getMonth() + 1
      m = m < 10 ? "0" + m : m
      return y + "-" + m
    },
    searchExpand () {
      if (this.searchFormExpand.month
        || this.searchFormExpand.useCode
        || this.searchFormExpand.serviceChargeCode
        || this.searchFormExpand.capName
        || this.searchFormExpand.coreName
        || this.searchFormExpand.channelName) {
        this.isExpand = 1
        this.$refs.refRecordList.getList({})
      } else {
        this.isExpand = 0
        this.$refs.refMonthList.getList({})
        this.$refs.refMonthList.expandKeys = []
      }
    },
    resetExpand () {
      this.searchFormExpand.capName = ''
      this.searchFormExpand.channelName = ''
      this.searchFormExpand.useCode = ''
      this.searchFormExpand.coreName = ''
      this.searchFormExpand.serviceChargeCode = ''
      this.isExpand = 0
      if (this.isExpand === 0) {
        this.$refs.refMonthList.getList()
        this.$refs.refMonthList.expandKeys = []
      }
    },
    getByMonth (searchForm) {
      this.loading.expandList = true
      this.api.serviceCharge.getByMonth(this.utils.filterTrimValue(searchForm)).then(res => {
        this.serviceList = res.data.data
      }).finally(() => {
        // 解放loading
        this.loading.expandList = false
      })
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:100%;
  }
</style>
