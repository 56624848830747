<!-- 服务费模板 -->
<template>
  <div>
    <table-inner v-loading="loading.list" :table-header="false" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :page-id="tableList.pageId" :row-key="getRowKeys" :expand-row-keys="expandKeys" @expand-change="expandChange" @changePageNum="changePageNum">
      <template slot="table-columns">
        <el-table-column type="expand">
          <template slot-scope="props">
            <div class="partition-area">
              <div class="list-btn-inner">
                <table-inner v-loading="expandLoading.eList" :table-header="false" :table-data="serviceList.list" :page-num="serviceList.pageNum" :page-size="serviceList.pageSize" :pages="serviceList.pages" :page-id="serviceList.pageId" @changePageNum="changeExpandPageNum">
                  <template slot="table-columns">
                    <el-table-column prop="useCode" label="用信编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="serviceChargeCode" label="服务费编号" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="serviceChargeAmount" label="服务费金额（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="capName" label="资方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="coreName" label="核心企业企业" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="channelName" label="运营方" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="createdAt" label="生成时间" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
                    <el-table-column prop="verifyStatus" label="状态" align="center" :formatter="(row, col, cell) => utils.statusFormat(cell, 'verifyStatus')" show-overflow-tooltip min-width="150" />
                    <el-table-column label="操作" fixed="right" align="center" show-overflow-tooltip min-width="150">
                      <template slot-scope="scope">
                        <a v-if="scope.row.verifyStatus === 0" href="javascript:" class="text-btn" @click="addExport(scope.row.id)">核销</a>
                        <a href="javascript:" class="text-btn" @click="$router.push({ name: 'serviceChargeRecordDetail', query: { id: scope.row.id } })">详情</a>
                      </template>
                    </el-table-column>
                  </template>
                </table-inner>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" align="center" show-overflow-tooltip min-width="100" />
        <el-table-column prop="month" label="时间（月）" align="center" :formatter="utils.isEffective" show-overflow-tooltip min-width="150" />
        <el-table-column prop="chargeAmount" label="服务费总金额（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
        <el-table-column prop="verifyAmount" label="已收取金额（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
        <el-table-column prop="useAmount" label="待收取金额（元）" align="center" :formatter="utils.tableMoneyFormat" show-overflow-tooltip min-width="150" />
      </template>
    </table-inner>
    <ics-common-upload-inner :upload-dialog="exportDialog" bucket="other" @onSuccessFile="onSuccessFile" />
  </div>
</template>
<script>
import IcsCommonUploadInner from '@/components/common-upload-inner'
class FormProp {
  id = ''
  fileList = []
}
export default {
  name: 'MonthList',
  components: {IcsCommonUploadInner},
  data () {
    return {
      formInfo: new FormProp(),
      tableList: {},
      serviceList: {},
      loading: {
        list: false
      },
      expandLoading: {
        eList: false,
        submit: false
      },
      expandChangeRow: {},
      expandedRows: [],
      // 获取row的key值
      getRowKeys(row) {
        return row.month;
      },
      searchForm: {},
      searchFormExpand: {},
      expandKeys: [],
      exportDialog: {
        title: '',
        visible: false
      },
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    getList() {
      this.loading.list = true
      this.api.serviceCharge.getDetailPage(this.utils.filterTrimValue(this.searchForm)).then(res => {
        this.tableList = res.data.data
        this.loading.list = false
      }).finally(() => {
        // 解放loading
        this.loading.list = false
      })
    },
    changePageNum(val) {
      this.searchForm.pageNum = val.pageNum
      this.getList(this.searchForm)
    },
    changeExpandPageNum(val) {
      this.searchFormExpand.pageNum = val.pageNum
      this.expandChange(this.expandChangeRow, this.expandedRows)
    },
    expandChange(row, expandedRows) {
      this.expandChangeRow = row
      this.expandedRows = expandedRows
      let that = this
      this.expandLoading.eList = true
      if (expandedRows.length) {
        that.expandKeys = []
        if (row) {
          that.expandKeys.push(row.month)
          const data = {
            pageNum: this.searchFormExpand.pageNum,
            month: row.month
          }
          this.api.serviceCharge.getByMonth(data).then(res => {
            this.serviceList = res.data.data
          }).finally(() => {
            // 解放loading
            this.expandLoading.eList = false
          })
        }
      } else {
        that.expandKeys = []
      }
    },
    addExport(id) {
      this.exportDialog.title = '上传核销文件'
      this.formInfo.id = id
      this.exportDialog.visible = true
    },
    onSuccessFile(response) {
      this.api.serviceCharge.verify(this.formInfo.id, response.data.path).then(result => {
        if (result.data.success === true) {
          this.expandLoading.submit = false
          this.$message.success('保存成功')
          this.exportDialog.visible = false
          this.formInfo = new FormProp()
          this.expandChange(this.expandChangeRow, this.expandedRows)
        } else {
          this.expandLoading.submit = false
          this.$message.error('保存失败')
        }
      }).finally(() => {
        this.expandLoading.submit = false
      })
    },
    closeExportDialog() {
      this.formInfo = new FormProp()
    }
  }
}
</script>
<style scoped>
  .input_w80{
    width:100%;
  }
</style>
